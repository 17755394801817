const functions = require("../functions").default;

export function ajaxEvents(context, htmlToImage, isEmpty) {
    window.dadosCadastroClienteCCL = null;
    window.addEventListener(
        "load",
        function (e) {
            const assinatura = $("#assinatura_iugu_cobranca").val();
            if (assinatura) {
                $.ajax({
                    url: siteUrl(
                        `/painel/assinaturas/dados-assinatura/${assinatura}`
                    ),
                    type: "get",
                    dataType: "html",
                    success: function (res) {
                        let data = JSON.parse(res);
                        data = data.data.expires_at;
                        data = data.split("-");
                        data = data[2] + "/" + data[1] + "/" + data[0];
                        $("#assinatura_data_cobranca").html(data);
                    },
                    error: function (err) {
                        console.log("err", err);
                    },
                });
            }
        },
        false
    );

    $(".js-select-estados", context).on("change", function (e) {
        $.ajax({
            url: siteUrl(`/cidades/busca/${this.value}`),
            type: "get",
            success: function (res) {
                $(".js-select-cidades option", context).remove();
                res.forEach(function (item) {
                    $(".js-select-cidades", context).append(
                        `<option value="${item.id}">${item.value}</option>`
                    );
                });
            },
            error: function (err) {
                console.log("err", err);
            },
        });
    });

    $(".js-busca-cep", context).on("blur", function (e) {
        functions.buscaCEP(this.value, context);
    });

    $(".js-load-eloca-score", context).on("click", function () {
        $("#overlayer-loading").addClass("show-overlayer");

        const url = $(this).data("url");
        $.ajax({
            url: url,
            type: "get",
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                $(".js-score-eloca", context).replaceWith(res);
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
            },
        });
    });

    $(".js-gerar-completar-cadastro-link", context).on("click", function () {
        $("#overlayer-loading").addClass("show-overlayer");
        const btn = $(this);
        const clienteId = $(this).data("id");
        const dominio = $(this).data("dominio");

        $.ajax({
            url: siteUrl(
                `/painel/clientes/cadastro-whatsapp/${clienteId}/null/true/`
            ),
            type: "GET",
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                const url = `${dominio}/complementar-cadastro/${res.copiar}`;
                if ($(".js-copy-clipboard").length) {
                    $(".js-copy-clipboard", context).attr("data-text", url);
                    $(".js-copy-clipboard", context).removeClass("d-none");
                    $(".js-copy-clipboard", context).click();
                } else {
                    $(".elemento-copy-link", context).attr("data-link", url);
                    $(".elemento-copy-link", context).removeClass("d-none");
                    $(".elemento-copy-link", context).click();
                }

                showToastSuccess(context, res.message);

                $(btn).addClass("d-none");
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
                console.error("err", err);
                showToastDanger(context, err.responseJSON.message);
            },
        });
    });

    $(".js-form-financeiro", context).on("submit", function (event) {
        event.preventDefault();
        if ($(".js-baixar-txt", context).is(":checked")) {
            $.ajax({
                url: this.action,
                type: "get",
                data: $(this).serialize(),
                success: function (res) {
                    $("#overlayer-loading").removeClass("show-overlayer");
                    var link = document.createElement("a");
                    link.href = res.url;
                    link.download = res.url;
                    link.target = "_blank";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                },
            });
        } else {
            $(this).unbind("submit");
            $(this).submit();
        }
    });

    $(".js-logs-faturas", context).on("click", function (e) {
        let id = $(this).attr("data-id");
        $("#listaFaturasModal").html(`
            <tr>
                <td colspan="3">
                    <p class="text-center"><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i></p>
                </td>
            </tr>
        `);

        $.ajax({
            url: siteUrl(`/painel/assinaturas/logs-fatura/${id}`),
            type: "get",
            success: function (res) {
                $("#listaFaturasModal").html("");
                res.forEach(function (item) {
                    $("#listaFaturasModal").append(
                        `<tr>
                            <td>${item.created_at}</td>
                            <td>${item.description}</td>
                            <td>${item.notes}</td>
                        </tr>`
                    );
                });
                if (res.length == 0) {
                    $("#listaFaturasModal").html(`
                        <tr>
                            <td colspan="3">
                                <p class="text-center">Sem Logs para exibição</p>
                            </td>
                        </tr>
                    `);
                }
            },
            error: function (err) {
                console.error("err", err);
                showToastDanger(context, err.responseJSON.message);
            },
        });
    });

    $(".js-calendario-conflito", context).on("click", function (e) {
        let produtoId = $(this).data("produto-id");
        let produtoNome = $(this).data("produto-nome");
        let conflitos = $(this).data("conflitos");
        $(".js-modal-title-conflitos").html(
            `Conflitos no produto: ${produtoId} - ${produtoNome}`
        );
        $.ajax({
            url: siteUrl(`/painel/patrimonios-conflitos-calendario`),
            type: "get",
            data: { produtoid: produtoId, conflitos: conflitos },
            dataType: "html",
            success: function (res) {
                $(".js-modal-body-conflitos").html(res);

                $(".js-show-modal-conflitos", context).modal({
                    show: true,
                });
            },
            error: function (err) {
                console.log("err", err);
                showToastDanger(context, "Ocorreu um erro");
            },
        });
    });

    $(".js-update-status-assinatura", context).on("change", function () {
        const statusId = $(this).val();
        const assinaturaId = $("#assinatura_id").val();

        $("#overlayer-loading").addClass("show-overlayer");
        $.ajax({
            url: siteUrl(
                `/painel/assinaturas/update-status/${assinaturaId}/${statusId}`
            ),
            type: "put",
            data: {},
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastSuccess(context, res.message);
                window.location.reload();
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(context, err.message);
            },
        });
    });

    $(context).on("click", ".js-resolver-conflitos", function () {
        const idItem = $(this).data("id-item");

        $("#overlayer-loading").addClass("show-overlayer");
        $.ajax({
            url: siteUrl(`/painel/patrimonios-conflitos/resolver-conflito/`),
            type: "put",
            data: {
                idItem: idItem,
            },
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastSuccess(context, res.mensagem);
                window.location.reload();
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(context, err.mensagem);
            },
        });
    });

    $(context).on("click", ".js-desfazer-resolver-conflito", function () {
        const idItem = $(this).data("id-item");

        $("#overlayer-loading").addClass("show-overlayer");
        $.ajax({
            url: siteUrl(
                `/painel/patrimonios-conflitos/desfazer-resolver-conflito/`
            ),
            type: "put",
            data: {
                idItem: idItem,
            },
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastSuccess(context, res.mensagem);
                window.location.reload();
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(context, err.mensagem);
            },
        });
    });

    $(context).on("click", ".js-consultar-credito-cliente", function () {
        let oportunidadeId = $(this).data("oportunidade_id").toString();
        oportunidadeId = oportunidadeId.replace(/\D/g, "");
        if (
            oportunidadeId == undefined ||
            oportunidadeId == null ||
            oportunidadeId <= 0
        ) {
            showToastDanger($("body"), "Sem id da oportunidade");
            return;
        }

        $("#overlayer-loading").addClass("show-overlayer");
        $.ajax({
            url: siteUrl(
                "/painel/consulta-credito-cliente-ccl?oportunidade_id=" +
                    oportunidadeId
            ),
            type: "get",
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                if (!res.erro) {
                    window.location.reload();
                } else {
                    showToastDanger($("body"), res.mensagem);
                }
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
            },
        });
    });

    $(context).on("click", ".js-consultar-cliente-ccl", function () {
        let cpfCNPJ = $("input[name='cnpj']").val();
        cpfCNPJ = cpfCNPJ.replace(/\D/g, "");
        if (isEmpty(cpfCNPJ)) {
            showToastDanger($("body"), "Você precisa preencher um CPF ou CNPJ");
            return;
        }

        if (cpfCNPJ.length != 11 && cpfCNPJ.length != 14) {
            showToastDanger($("body"), `CPF ou CNPJ ${cpfCNPJ} inválido`);
            return;
        }

        $(".js-produtos-ccl").html("");
        $("#overlayer-loading").addClass("show-overlayer");

        $.ajax({
            url: siteUrl("/painel/clientes/consulta-ccl/" + cpfCNPJ),
            type: "get",
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                if (!res.erro) {
                    $("#modalAlertPainel").modal("toggle");
                    let dados = res.dados;
                    if (dados.code == 200) {
                        let data = dados.data;
                        window.dadosCadastroClienteCCL = dados;
                        if (dados?.tipoPessoa == "pf") {
                            $("span", ".js-cliente-ccl-nome").html(data?.nome);
                            $("span", ".js-cliente-ccl-rg").html(data?.RG);
                            $("span", ".js-cliente-ccl-data_nascimento").html(
                                data?.dataDeNascimento
                            );

                            $("span", ".js-cliente-ccl-email").html(data?.email);
                            $("span", ".js-cliente-ccl-telefone").html(
                                data?.telefone
                            );
                            $("span", ".js-cliente-ccl-bairro").html(
                                data?.endereco?.bairro
                            );
                            $("span", ".js-cliente-ccl-cep").html(
                                functions.preparaCepCCL(data?.endereco?.cep)
                            );
                            $("span", ".js-cliente-ccl-cidade").html(
                                data?.endereco?.municipio
                            );
                            $("span", ".js-cliente-ccl-estado").html(
                                data?.endereco?.uf
                            );
                            $("span", ".js-cliente-ccl-complemento").html(
                                data?.endereco?.complemento
                            );
                            $("span", ".js-cliente-ccl-numero").html(
                                data?.endereco?.numero
                            );
                            $("span", ".js-cliente-ccl-rua").html(
                                data?.endereco?.logradouro
                            );

                            $(".js-cliente-pj").hide();
                            $(".js-cliente-pf").show();
                        } else if (dados?.tipoPessoa == "pj") {
                            let dataAbertura = data?.dataDeAbertura;
                            $("span", ".js-cliente-ccl-fantasia").html(
                                data?.nomeFantasia
                            );
                            $("span", ".js-cliente-ccl-razao").html(
                                data?.razaoSocial
                            );
                            $("span", ".js-cliente-ccl-data_cadastro").html(
                                dataAbertura
                            );
                            $("span", ".js-cliente-ccl-email").html(data?.email);
                            $("span", ".js-cliente-ccl-telefone").html(
                                data?.telefone
                            );
                            $("span", ".js-cliente-ccl-bairro").html(
                                data?.bairroEmpresa
                            );
                            $("span", ".js-cliente-ccl-cep").html(
                                functions.preparaCepCCL(data?.cepEmpresa)
                            );
                            $("span", ".js-cliente-ccl-cidade").html(
                                data?.cidadeEmpresa
                            );
                            $("span", ".js-cliente-ccl-estado").html(
                                data?.estadoEmpresa
                            );
                            $("span", ".js-cliente-ccl-complemento").html(
                                data?.complementoEmpresa
                            );
                            $("span", ".js-cliente-ccl-numero").html(
                                data?.numeroEmpresa
                            );
                            $("span", ".js-cliente-ccl-rua").html(
                                data?.ruaEmpresa
                            );

                            $(".js-cliente-pf").hide();
                            $(".js-cliente-pj").show();
                        }
                    } else {
                        showToastDanger($("body"), "Não encontrado");
                    }
                } else {
                    showToastDanger($("body"), res.mensagem);
                }
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
            },
        });
    });

    $(context).on(
        "click",
        ".js-sendmessage-dashboard-whatsapp-web",
        function () {
            let sessionId = $(this).data("session-id");
            $.ajax({
                url: siteUrl(
                    `/painel/dashboard-whatsapp-web/send-message/${sessionId}`
                ),
                type: "get",
                success: function (res) {
                    if (!res.error && !isEmpty(res.messageId)) {
                        showToastSuccess(context, "Mensagem enviada");
                    } else {
                        showToastDanger(
                            context,
                            `Mensagem não enviada: , ${res.message}`
                        );
                    }
                },
                error: function (err) {
                    showToastDanger(
                        context,
                        "Ocorreu um erro ao tentar enviar a mensagem"
                    );
                },
            });
        }
    );

    $(".js-registrar-channel", context).on("click", function (e) {
        $.ajax({
            url: siteUrl(`/painel/lojas/goto/channel`),
            type: "post",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                channel: $("input[name='voip_goto_channel']").val(),
                account_key: $("textarea[name='voip_goto_account_key']").val(),
            },
            success: function (res) {
                if (!res.error) {
                    showToastSuccess(context, res.message);
                } else {
                    showToastDanger(context, res.message);
                }
            },
            error: function (err) {
                console.log(err);
                showToastDanger(
                    context,
                    "Ocorreu um erro, consulte o log do console"
                );
            },
        });
    });

    $(".js-delete-channel", context).on("click", function (e) {
        $.ajax({
            url: siteUrl(
                `/painel/lojas/goto/channel/${$(this).data("channel-id")}`
            ),
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            type: "delete",
            success: function (res) {
                if (!res.error) {
                    window.location.reload(true);
                } else {
                    showToastDanger(context, res.message);
                }
            },
            error: function (err) {
                console.log(err);
                showToastDanger(
                    context,
                    "Ocorreu um erro, consulte o log do console"
                );
            },
        });
    });

    $(".js-mover-oportunidades", context).on("click", function () {
        $("#overlayer-loading").addClass("show-overlayer");
        const btn = this;
        const modal = $(this).closest(".modal");
        let dados = $(modal).find(".custom-select").val();
        dados = dados.split("-");
        const usuario = $(this).attr("item-id");
        $.ajax({
            url: siteUrl(
                `/painel/usuarios/mover-oportunidade-pipeline/${usuario}`
            ),
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                pipeline_id: dados[0],
                novo_usuario_id: dados[1],
            },
            type: "post",
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastSuccess(context, res.message);
                const bloco = $(modal)
                    .closest(".bloco-pipeline")
                    .find(".js-pipeline-bloco");
                $(bloco).attr("data-id", 0);
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
                console.log(err);
                const pipeline = $(btn).attr("data-pipeline");
                $(`input[name='pipelines[${pipeline}]']`).trigger("click");
                showToastDanger(context, "Ocorreu um erro!");
            },
        });
    });

    $(".js-att-preco", context).on("blur", function (e) {
        const td = $(this).closest("td");
        const idProduto = $(".js-id-produto", td).val();
        const tipo = $(".js-tipo").val();
        const etapa = $(".js-etapa").val();

        const precoAntigoProduto = parseFloat(
            $(".js-valor-antigo-produto", td).val()
        );

        const novoPrecoProduto = parseFloat(
            this.value.replaceAll(".", "").replace(",", ".")
        );

        $("#overlayer-loading").addClass("show-overlayer");

        $.ajax({
            url: siteUrl(`/painel/${tipo}/${etapa}/att/${idProduto}`),
            type: "post",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                preco: novoPrecoProduto,
            },
            success: function (res) {
                functions.recalcularPrecoProdutos(context);

                $("#overlayer-loading").removeClass("show-overlayer");

                if (
                    (novoPrecoProduto > 0 && precoAntigoProduto <= 0) ||
                    (novoPrecoProduto <= 0 && precoAntigoProduto > 0)
                ) {
                    location.reload();
                }
            },
            error: function (err) {
                console.log("err", err);
            },
        });
    });

    $(".js-switch-custo-instalacao", context).on("change", function () {
        let url = $("#url-remove-custo").val();
        if ($(this).is(":checked")) {
            url = $("#url-add-custo").val();
            const custo = parseFloat($(".js-custo-instalacao", context).val());
            $(".js-show-custo-instalacao", context).html(
                `R$ ${custo.toFixed(2).replace(".", ",")}`
            );
        } else {
            $(".js-show-custo-instalacao", context).html("Sem custo");
        }
        $("#overlayer-loading").addClass("show-overlayer");
        $.ajax({
            url: url,
            type: "get",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
            },
        });
        functions.recalcularPrecoProdutos(context);
    });

    $(".js-file-input", context).on("change", function () {
        const input = this;
        const extensoesAceitas = $(this).data("extensao");
        const maxSize = $(this).data("max-size");
        const url = $(this).val();
        const ext = url.substring(url.lastIndexOf(".") + 1).toLowerCase();
        const inputId = input.id.replaceAll("[", "-").replaceAll("]", "-");
        const bloco = $(this).closest(".custom-form-file");

        if (!input.files || !input.files[0]) {
            $(input).addClass("is-invalid");
            return;
        }

        let fileSize = input.files[0].size / 1000;

        if (
            fileSize < maxSize &&
            extensoesAceitas.find((extensao) => extensao == ext)
        ) {
            const reader = new FileReader();

            let unidadeMedida = "KB";

            if (fileSize > 1000) {
                unidadeMedida = "MB";
                fileSize = fileSize / 1000;
            }

            let fileTotal = `<span class="small">Tamanho: ${fileSize.toFixed(
                2
            )} ${unidadeMedida} </span>`;

            const imagens = ["jpg", "jpeg", "png", "gif", "webp", "ico"];

            $(".js-file-size", bloco).html(fileTotal);

            if (imagens.find((img) => img == ext)) {
                $(".uploading-loading", bloco).addClass("active");
            } else {
                $("#overlayer-loading").addClass("show-overlayer");
            }

            reader.onload = function (e) {
                if (imagens.find((img) => img == ext)) {
                    $(`#thumb-${inputId}`, context).attr(
                        "src",
                        e.target.result
                    );
                    $(`#thumb-${inputId}`, context)
                        .closest("div")
                        .removeClass("d-none");
                }
            };
            reader.readAsDataURL(input.files[0]);

            Vapor.store(input.files[0], {
                baseURL: siteUrl("/painel"),
                progress: (progress) => {
                    this.uploadProgress = Math.round(progress * 100);
                },
            }).then((response) => {
                const filename = this.files[0].name;
                const labelText = $('label[for="' + this.id + '"]');
                labelText.text(filename);
                $(`#id-input-${inputId}`, context).val(response.uuid);
                $(`#id-input-${inputId}-ext`, context).val(ext);
                if (imagens.find((img) => img == ext)) {
                    $(".uploading-loading", bloco).removeClass("active");
                } else {
                    $("#overlayer-loading").removeClass("show-overlayer");
                }
                if ($(input).hasClass("is-invalid")) {
                    $(input).removeClass("is-invalid");
                }
            });
        } else {
            const labelText = $('label[for="' + this.id + '"]');
            labelText.text("Arquivo");
            $(input).addClass("is-invalid");
        }
    });

    if ($(`.js-row-whatsapp-web-qrcode`, context).length) {
        let sessaoId = $(`.js-row-whatsapp-web-qrcode`, context).data(
            "sessao-id"
        );
        setInterval(function () {
            functions.qrCodeAjax(context, sessaoId);
        }, 10000);
    }

    let popupAuth2Goto = "";
    $(".js-goto-authorization-code", context).on("click", function (e) {
        let state = $(this).data("state");
        let urlCallBack = siteUrl(`/api/goto/retorno-oauth2/${window.lojaId}/`);
        let url = `https://authentication.logmeininc.com/oauth/authorize?response_type=code&client_id=${window.clienteId}&state=${state}&redirect_uri=${urlCallBack}`;
        let parametrosPopup =
            "width=600,height=400,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,copyhistory=no,resizable=yes";
        popupAuth2Goto = window.open(url, "_blank", parametrosPopup);
        popupAuth2Goto.onunload = function () {
            const parametrosCallBack = popupAuth2Goto.location.href;
            if (parametrosCallBack.startsWith(urlCallBack)) {
                const BuscaParametros = new URLSearchParams(
                    parametrosCallBack.split("?")[1]
                );
                const code = BuscaParametros.get("code");
                if ($("input[name='authorization_code']").length) {
                    $("input[name='authorization_code']").val(code);
                }
                if ($("input[name='voip_goto_authorization_code']").length) {
                    $("input[name='voip_goto_authorization_code']").val(code);
                    $("input[name='voip_goto_access_token']").val("");
                    $("input[name='voip_goto_refresh_token']").val("");
                    $("input[name='voip_goto_token_principal']").val("");
                }
            }
        };
    });

    $(".js-enviar-orcamento-conversa", context).on("click", function () {
        const configId = $("#config_pdf").val();
        $("#escolherConfig").modal("toggle");
        $("#modalImprimirOportunidade").modal("toggle");
        $(`#bloco-chat-tab-${configId}`, context).trigger("click");
        $(".js-input-chat", context).val(" ");
        const node = document.querySelector(
            "#modalImprimirOportunidadeConteudo"
        );

        const filter = (node) => {
            const exclusionClasses = ["img-fluid"];
            return !exclusionClasses.some((classname) =>
                node.classList?.contains(classname)
            );
        };

        htmlToImage
            .toPng(node, { filter: filter })
            .then(function (dataUrl) {
                const sliceSize = 1024;
                dataUrl = dataUrl.replace("data:image/png;base64,", "");
                const byteCharacters = window.atob(dataUrl);
                const bytesLength = byteCharacters.length;
                const slicesCount = Math.ceil(bytesLength / sliceSize);
                const byteArrays = new Array(slicesCount);

                for (
                    let sliceIndex = 0;
                    sliceIndex < slicesCount;
                    ++sliceIndex
                ) {
                    let begin = sliceIndex * sliceSize;
                    let end = Math.min(begin + sliceSize, bytesLength);

                    let bytes = new Array(end - begin);
                    for (
                        let offset = begin, i = 0;
                        offset < end;
                        ++i, ++offset
                    ) {
                        bytes[i] = byteCharacters[offset].charCodeAt(0);
                    }
                    byteArrays[sliceIndex] = new Uint8Array(bytes);
                }
                const blop = new Blob(byteArrays, { type: "image/png" });
                functions.uploadImg(
                    blop,
                    "image",
                    $(`#input-file-chat-${configId}`, context)[0]
                );
            })
            .catch(function (error) {
                showToastDanger(
                    context,
                    "Ocorreu uma falha ao gerar a imagem."
                );
                console.error("oops, alog deu errado!", error);
            });
    });

    $(".js-autocomplete-produto-relatorio-assinatura", context).each(
        function () {
            const input = this;
            $(input)
                .autocomplete({
                    source: siteUrl(
                        "/painel/produtos/busca/autocomplete?crm=true"
                    ),
                    minLength: 2,
                    select: function (event, ui) {
                        $(".js-busca-form-filtros-relatorio-assinatura").val(
                            ui.item.id
                        );
                    },
                    error: function (err) {
                        $("#overlayer-loading").removeClass("show-overlayer");
                        showToastDanger(context);
                    },
                })
                .autocomplete("instance")._renderItem = function (ul, item) {
                let sku = "";
                if (item.sku && item.sku != null) {
                    sku = ` - SKU: ${item.sku}`;
                }

                return $("<li class='lista-produto'>")
                    .append(`#${item.id} - ${item.nome}${sku}`)
                    .appendTo(ul);
            };
        }
    );
}
