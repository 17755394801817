const functions = require("../functions").default;

const {
    carregarTabelaChat,
} = require("../functions/miscellaneous");

export function chatEvents(context, isEmpty) {
    //websocket ElocaHub
    if ($(".js-eloca-hub-bell", context).length) {
        functions.requestNotificationPermission();
        functions.conexaoWebSocketElocaHub(context);
    }
    if (
        $(".js-container-chat", context).length &&
        $(".js-aba-chat.active", context).length
    ) {
        const newContext = $(
            $(".js-aba-chat.active", context).data("tab"),
            context
        );
        functions.calculaAlturaScroll(newContext);
    }
    $(".js-aba-chat", context).on("click", function () {
        const newContext = $($(this).data("tab"), context);

        if ($(".js-radio-template-id").length) {
            $(".js-radio-template-id").removeClass("active");
            $("input[name='template_id']").prop("checked", false);
        }
        functions.calculaAlturaScroll(newContext);

        const id = $(this).data("id");
        const opId = $(this).data("oportunidade");
        const sidebar = `#produto-chat-${opId}-${id}`;

        if (!$(sidebar).hasClass("d-none")) {
            const tabela = `#tabela-produtos-chat-${opId}-${id}`;
           carregarTabelaChat(tabela, opId);
        }

    });

    $(".js-btn-chat", context).on("click", function () {
        if ($(".js-input-chat", context).val().length > 4096) {
            alert("Você ultrapassou o limite de 4096 caracteres.");
            return;
        }
        functions.enviaMensagemChat($(this).closest(".oportunidade-chat"));
    });

    $(".js-input-chat", context).on("keydown", function (event) {
        if (event.which == 13 && !event.shiftKey) {
            if ($(this, context).val().length > 0) {
                if ($(this, context)[0].selectionStart !== undefined) {
                    event.preventDefault();
                }
            }
        }
    });
    $(".js-input-chat", context).on("keyup", function (event) {
        const divRespostasRapidas = $(
            ".js-div-respostas-rapidas-chat",
            context
        );
        let alturaDivRespostasRapidas = 0;

        if (divRespostasRapidas.length > 0) {
            alturaDivRespostasRapidas = parseFloat(
                divRespostasRapidas.css("height").replace(/\D/g, "")
            );
        }

        if (
            (event.key == "Backspace" || event.key == "Delete") &&
            $(this, context).val().length == 0 &&
            alturaDivRespostasRapidas > 0
        ) {
            divRespostasRapidas.toggleClass("expandir-div-respostas-rapidas");
        }

        if (
            event.key == "/" &&
            $(this, context).val().length == 1 &&
            alturaDivRespostasRapidas <= 0
        ) {
            divRespostasRapidas.toggleClass("expandir-div-respostas-rapidas");
            let respostas = $(".js-respostas-rapidas-resposta", context);
            for (let i = 0; i < respostas.length; i++) {
                if ($(respostas[i]).hasClass("d-none")) {
                    $(respostas[i]).removeClass("d-none");
                }
            }
        }

        if (
            $(this, context).val().charAt(0) == "/" &&
            $(this, context).val().length > 1
        ) {
            let valor = $(this, context).val().substring(1);
            valor = valor.toLowerCase();

            let respostas = $(".js-respostas-rapidas-resposta", context);
            for (let i = 0; i < respostas.length; i++) {
                if (valor.length > 1) {
                    let nome = $(respostas[i])
                        .find(".js-respostas-rapidas-resposta-nome")
                        .html();
                    let resposta = $(respostas[i])
                        .find(".js-respostas-rapidas-resposta-resposta")
                        .html();
                    if (
                        nome.toLowerCase().includes(valor) ||
                        resposta.toLowerCase().includes(valor)
                    ) {
                        if ($(respostas[i]).hasClass("d-none")) {
                            $(respostas[i]).removeClass("d-none");
                        }
                    } else {
                        if (!$(respostas[i]).hasClass("d-none")) {
                            $(respostas[i]).addClass("d-none");
                        }
                    }
                } else {
                    if ($(respostas[i]).hasClass("d-none")) {
                        $(respostas[i]).removeClass("d-none");
                    }
                }
            }
        }

        if (event.which == 13 && !event.shiftKey) {
            if ($(this, context).val().length > 4096) {
                alert("Você ultrapassou o limite de 4096 caracteres.");
                return;
            }
            $(this).blur();
            functions.enviaMensagemChat($(this).closest(".oportunidade-chat"));
        }
    });

    $(".js-img-video-input-chat", context).on("change", function () {
        let arquivos = this.files;
        if (arquivos.length) {
            let files = functions.filesToArray(arquivos);
            for (let arquivo of files) {
                if (arquivo.type.includes("video")) {
                    //vídeo
                    functions.uploadOtherFile(arquivo, "video", this);
                } else {
                    //imagem
                    functions.uploadImg(arquivo, "image", this);
                }
            }
        }
    });

    $(".js-file-input-chat", context).on("change", function () {
        const tipo = $(this).data("tipo");
        let arquivos = this.files;
        if (arquivos.length) {
            let files = functions.filesToArray(arquivos);
            for (let arquivo of files) {
                functions.uploadOtherFile(arquivo, tipo, this);
            }
        }
    });

    $(".js-open-microphone", context).on("click", function () {
        const contextChat = $(this).closest(".oportunidade-chat");

        if (navigator.mediaDevices.getUserMedia) {
            const MicRecorder = require("mic-recorder-to-mp3");
            const recorder = new MicRecorder({
                bitRate: 128,
            });

            // Start recording. Browser will request permission to use your microphone.
            recorder
                .start()
                .then(() => {
                    functions.showBlocoChatAudio(contextChat);

                    setTimeout(() => {
                        if (recorder.activeStream.active) {
                            $(".js-btn-stop-audio", contextChat).addClass(
                                "d-none"
                            );
                            $(".js-btn-enviar-audio", contextChat).removeClass(
                                "d-none"
                            );
                            recorder.stop();
                            functions.previewAudio(recorder, contextChat);
                        }
                    }, 120000);

                    $(".js-btn-close-audio", contextChat).unbind("click");
                    $(".js-btn-close-audio", contextChat).on(
                        "click",
                        function () {
                            recorder.stop();
                            $(".js-bloco-texto", contextChat).removeClass(
                                "d-none"
                            );
                            $(".js-bloco-audio", contextChat).addClass(
                                "d-none"
                            );
                        }
                    );
                    $(".js-btn-stop-audio", contextChat).unbind("click");
                    $(".js-btn-stop-audio", contextChat).on(
                        "click",
                        function () {
                            $(".js-btn-stop-audio", contextChat).addClass(
                                "d-none"
                            );
                            $(".js-btn-enviar-audio", contextChat).removeClass(
                                "d-none"
                            );
                            recorder.stop();
                            functions.previewAudio(recorder, contextChat);
                        }
                    );
                })
                .catch((e) => {
                    console.error(e);
                });
        } else {
            console.log("não suportado");
        }
    });

    $(".js-input-chat", context).on("paste", function (event) {
        functions.colarImagemChat($(this).closest(".oportunidade-chat"), event);
        functions.setalturaTextArea($(this));
    });

    $(".js-confirma-reiniciar-service-sessao", context).on(
        "click",
        function (event) {
            event.preventDefault();
            var confirma = confirm("Tem certeza?");
            if (confirma) {
                window.location.href = $(this).prop("href");
            }
        }
    );

    $(".js-input-chat", context).on("input", function () {
        if ($(this).val().length < 10) {
            $(".js-id-produto", context).val("");
        }
        functions.setalturaTextArea($(this));
    });

    $(".js-respostas-rapidas-resposta", context).on("click", function () {
        let configId = $(this).data("config-id");
        $(".js-respostas-rapidas-resposta", context).removeClass("active");
        $(this, context).addClass("active");

        let resposta = $(
            ".js-respostas-rapidas-resposta-resposta",
            $(this)
        ).html();
        $(".js-input-chat", $(`.chat_rodape${configId}`)).val(resposta);
        $(".js-input-chat").select();
        const divRespostas = $(".js-div-respostas-rapidas-chat", context);

        let alturaDivRespostasRapidas = parseFloat(
            divRespostas.css("height").replace(/\D/g, "")
        );

        if (alturaDivRespostasRapidas > 0) {
            divRespostas.toggleClass("expandir-div-respostas-rapidas");
        }
        functions.setalturaTextArea($(".js-input-chat"));

    });

    $(".js-select-tipo-integracao", context).on("change", function () {
        functions.inputsConfigWhatsAppShowHide(this);
    });

    $(".js-btn-envio-tamplate", context).on("click", function () {
        if (isEmpty($("input[name=template_id]:checked").val())) {
            showToastDanger(context, "Por favor escolha um template.");
            return;
        }

        $("#js-input-chat", context).val("");

        let radioButton = $("input[name=template_id]:checked");
        functions.enviaMensagemChat(
            $(`#bloco-chat-${radioButton.data("config-id")}`),
            null,
            null,
            null,
            null,
            null,
            radioButton
        );
        $("#modalTemplate").modal("toggle");
    });

    $(".js-radio-template-id", context).on("click", function () {
        $(".js-radio-template-id").removeClass("active");
        $(this).addClass("active");
        $("input[name=template_id]", this).prop("checked", true);
    });

    $(".js-botao-abrir-modal-template", context).on("click", function () {
        let radio = $(`${$(this).data("modal-id")} input[type="radio"]:first`);
        radio.prop("checked", true);

        $(".js-radio-template-id").removeClass("active");
        let li = $(`${$(this).data("modal-id")} .js-radio-template-id:first`);
        li.addClass("active");
    });

    window.addEventListener(
        "message",
        function (e) {
            var eventName = e.data[0];
            var data = e.data[1];
            switch (eventName) {
                case "setHeightIFrame":
                    $(`#${data["id"]}`).height(data["height"]);
                    break;
            }
        },
        false
    );

    $(document).on("click", function (event) {
        if (!$(event.target).closest(".js-div-respostas-rapidas-chat").length) {
            functions.setalturaTextArea($(this));
            if ($(".js-div-respostas-rapidas-chat").length > 0) {
                $(".js-div-respostas-rapidas-chat").each(function (
                    index,
                    element
                ) {
                    const divRespostas = $(element);
                    let alturaDivRespostasRapidas = parseFloat(
                        divRespostas.css("height").replace(/\D/g, "")
                    );

                    if (alturaDivRespostasRapidas > 0) {
                        divRespostas.toggleClass(
                            "expandir-div-respostas-rapidas"
                        );
                        $(".js-input-chat", divRespostas).val("");
                    }
                });
            }
        }
    });
}
