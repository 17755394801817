const {
    limpaMoeda,
    isEmpty,
} = require("./miscellaneous");
export function adicionarNovoItemLista() {
    $(".js-lista-body").append(`
            <div class="item-lista">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-11">
                        <li>
                            <div class="form-group">
                                <label class="small mb-1 d-none" for="conteudo[]"> </label>
                                <div class=" ">
                                    <input class="form-control custom-input js-input-lista" id="conteudo[]" name="conteudo[]" type="text" value="" placeholder="Valor" required>
                                </div>
                            </div>
                        </li>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-1 text-right">
                        <div class="form-group">
                            <div>
                                <button type="button" class="btn btn-delete js-button-delete-item-lista">
                                    <i class="fas fa-times mr-1 ml-1"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `);
}

export function displayCardTipo() {
    if ($("#js-select-lista-tipo option:selected").val() == 2) {
        //2 == Lista
        $(".js-card-lista-tipo").show();
    } else {
        if ($(".js-lista-body").length > 0) {
            $(".js-lista-body").html("");
        }
        $(".js-card-lista-tipo").hide();
    }
}

export function requestNotificationPermission() {
    if (!("Notification" in window)) {
        console.warn("This browser does not support desktop notification");
    } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
            console.log("Permissão liberada!");
        });
    } else {
        console.warn("This browser does not support desktop notification");
    }
}

export function showBrowserNotification(title, body, url, icon) {
    if (Notification.permission === "granted") {
        const options = {
            body: body,
            icon:
                icon ||
                "https://d1p6nzzdute2g.cloudfront.net/lojas/loja-14/a59e96c7-acb6-45b0-82df-729af5cea59b",
        };
        const notification = new Notification(title, options);
        notification.onclick = function (event) {
            event.preventDefault();
            window.location = url;
        };
    } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                showBrowserNotification(title, body, url, icon);
            }
        });
    }
}

export function fallbackCopyTextToClipboard(text, element) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    if ($(element).closest(".js-bloco-clipboard").length) {
        let bloco = $(element).closest(".js-bloco-clipboard");
        $(bloco).append(textArea);
    } else {
        $("body").append(textArea);
    }
    textArea.focus();
    textArea.select();

    try {
        let copy = document.execCommand("copy");
    } catch (err) {
        //console.error("Fallback: Oops, unable to copy", err);
    }
    $(textArea).remove();
}

export function copyTextToClipboard(text, element) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text, element);
        return;
    }
    navigator.clipboard.writeText(text);
}

export function preparaCepCCL(cep) {
    if (!isEmpty(cep)) {
        cep = cep.replace(/[^0-9]/g, "").toString();
        if (cep.length >= 3) {
            const tresUltimosDigitos = cep.slice(-3);
            return cep.slice(0, -3) + "-" + tresUltimosDigitos;
        } else {
            return cep;
        }
    }
}
