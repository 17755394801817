const htmlToImage = require("html-to-image");

const { isEmpty } = require("./default_parts/functions/miscellaneous");
const { displayCardTipo } = require("./default_parts/functions/ui_interactions");
const { inputsConfigWhatsAppShowHide } = require("./default_parts/functions/chat_features");

const { ajaxEvents } = require("./default_parts/events/ajax_events");
const { chatEvents } = require("./default_parts/events/chat_events");
const { miscellaneousEvents } = require("./default_parts/events/miscellaneous_events");
const { modalEvents } = require("./default_parts/events/modal_events");
const { tooltipEvents } = require("./default_parts/events/tooltip_events");
const { uiEvents } = require("./default_parts/events/ui_events");
const { validationEvents } = require("./default_parts/events/validation_events");

Eloca.behaviors.default = function (context) {
    displayCardTipo();

    $(document).ready(function () {
        inputsConfigWhatsAppShowHide(".config-tipo-integracao");
    });
    ajaxEvents(context, htmlToImage, isEmpty);
    chatEvents(context, isEmpty);
    miscellaneousEvents(context);
    modalEvents(context, isEmpty);
    tooltipEvents(context);
    uiEvents(context, isEmpty);
    validationEvents(context);
};
