const functions = require("../functions").default;

export function uiEvents(context, isEmpty) {
    $(".js-change-select", context).on("change", function () {
        if (this.value) {
            window.location.href = this.value;
        }
    });

    $(".js-custom-switch", context).on("change", function () {
        let bloco = $(this).closest(".js-checkbox-block").data("bloco");
        $(`.${bloco}`, context).toggleClass("d-none");
    });

    $(".js-copy-clipboard", context).on("click", function () {
        functions.copyTextToClipboard($(this).data("text"), this);
        $(this).html('<span class="text-success">Copiado!</span>');
    });

    $(".js-input-promocao", context).on("change keyup paste", function () {
        let value = this.value;
        $(".js-input-content.preco_promocao").text(value);
        if (value == null || value == "") {
            $(".js-input-content.preco").removeClass("promo");
        } else {
            $(".js-input-content.preco").addClass("promo");
        }
    });
    // Preview Texto
    $(".js-input-text", context).on("change keyup", function () {
        let field = $(this).closest(".form-group").data("input");
        $(`.js-input-content.${field}`).text(this.value);
    });
    // Resumo
    $(".js-textarea-text", context).on("change keyup paste", function () {
        $(".js-input-content.resumo").text(this.value);
    });
    // Faixas
    $(".js-exibicao-faixa", context).on("change", function () {
        $(".js-faixa-dropdown").toggleClass("d-none");
        $(".js-faixa-blocos").toggleClass("d-none");
    });
    // Estoque
    $(".js-select-option", context).on("change", function () {
        let status = this.value;
        if (status == 2) {
            $(".status_estoque.js-status-estoque").html(
                "<span class='text-danger'>Fora de Estoque</span>"
            );
        } else if (status == 3) {
            $(".status_estoque.js-status-estoque").html(
                "<span class='text-primary'>Sob consulta</span>"
            );
        } else {
            $(".status_estoque.js-status-estoque").html(
                "<span class='text-success'>Em estoque</span>"
            );
        }
    });

    // Modelo de Contrato
    $(".js-modelo-contrato-selecionado").on("change", function () {
        $(".js-btn-atualizar-modelo").removeClass("d-none");
    });

    // Menu customizado
    $(".js-select-tipo-menu", context).on("change", function (e) {
        let optionSelected = e.target.value;
        $(".js-option-menu").addClass("d-none");
        $(`.js-option-${optionSelected}`).removeClass("d-none");
    });

    $(".js-tipo-tarefa", context).on("change", function () {
        let id = $(this).prop("id");
        id = id.replaceAll("tipo", "");
        $(".js-div-mensagem-ativa").addClass("d-none");
        $(`#template_id${id}`).attr("required", false);

        if ($(this).val() == "whatsapp") {
            $(`#template_id${id}`).attr("required", true);
            $(".js-div-mensagem-ativa").removeClass("d-none");
        }
    });

    $(".js-mostrar-contratos-adicionais", context).on("change", function () {
        if ($(".js-mostrar-contratos-adicionais").is(":checked")) {
            $(".js-contratos-adicionais", context).removeClass("d-none");
        } else {
            $(".js-contratos-adicionais", context).addClass("d-none");
        }
    });

    $(".js-calculo-cobranca-adicional", context).on("change", function () {
        functions.calcChargeValue();
    });

    $(".js-color-picker", context).on("change", function () {
        let group = $(this).closest(".input-group");
        $(".js-color-text", group).val(this.value);
        $(".js-color-text", group).trigger("change");
    });
    $(".js-color-text", context).on("change", function () {
        let group = $(this).closest(".input-group");
        $(".js-color-picker", group).val(this.value);
    });

    $(".js-custom-radio-option", context).on("change", function () {
        let formCheck = $(this).closest(".js-form-check");
        $("div.card-selected", formCheck).removeClass("card-selected");
        $(this, formCheck)
            .closest(".checkout__radio")
            .addClass("card-selected");
    });

    $(".js-delete-image", context).on("click", function () {
        const name = $(this).data("name");
        const inputId = name.replace("[", "-").replace("]", "-");
        $(`#thumb-${inputId}`).attr("src", "");
        $(`#thumb-${inputId}`).closest("div").addClass("d-none");
        $(`#id-input-${inputId}`).val("");
    });

    $(".js-form-prevent-resend", context).on("submit", function () {
        $("#overlayer-loading").addClass("show-overlayer");
        $("button", this).attr("disabled", "disabled");
    });

    $("#js-select-lista-tipo", context).on("change", function () {
        $("#js-select-lista-tipo option").removeAttr("selected");
        $("#js-select-lista-tipo option[value='" + $(this).val() + "']").attr(
            "selected",
            "selected"
        );
        functions.displayCardTipo();
    });

    $(context).on("click", ".js-button-delete-item-lista", function () {
        var itemLista = $(this).parent().parent().parent().parent().parent();
        var itemListaClass = itemLista.attr("class");

        if (!isEmpty(itemListaClass)) {
            if (itemListaClass == "item-lista") {
                itemLista.remove();
            } else {
                showToastDanger(context, "Ocorreu um erro ao tentar excluir");
            }
        }
    });

    $(".js-adicionar-item-lista-tipo", context).on("click", function () {
        functions.adicionarNovoItemLista();
    });

    $(".js-fatura-avulsa-cartao", context).on("change", function () {
        if (this.value == "cartao") {
            $(".js-fatura-avulsa-parcelas", context).removeAttr("disabled");
        } else {
            $(".js-fatura-avulsa-parcelas", context).attr(
                "disabled",
                "disabled"
            );
        }
    });

    $(".js-habilita-token-contrato", context).on("change", function () {
        if (
            !$("#notificacao_sms").is(":checked") &&
            !$("#notificacao_whatsapp").is(":checked")
        ) {
            $("#notificacao_email").prop("checked", true);
        }
    });

    $(".js-busca-extrato-iugu").on("change", function () {
        const inicio = $("[name='inicio']").val();
        const buscar = $("[name='buscar']").val();
        const ano = $("[name='ano']").val();
        const mes = $("[name='mes']").val();
        const queryString = `?inicio=${inicio}&buscar=${buscar}&ano=${ano}&mes=${mes}`;
        const url = siteUrl(`/painel/extrato-iugu/exportar/${queryString}`);
        $("#linkExportExtrato").attr("href", url);
    });
    $(".js-busca-form-faturas-iugu").on("change", function () {
        const buscar = $("[name='buscar']").val();
        const ano = $("[name='ano']").val();
        const mes = $("[name='mes']").val();
        const status = $("[name='status']").val();
        const queryString = `?buscar=${buscar}&ano=${ano}&mes=${mes}&status=${status}`;
        const url = siteUrl(`/painel/extrato-iugu/exportar/${queryString}`);
        $("#linkExportFaturas").attr("href", url);
    });

    $(".js-form-filtro-oportunidades").on("change", function () {
        const data_inicio = $("[name='data_inicio']").val();
        const data_fim = $("[name='data_fim']").val();
        const status = $("[name='status']").val();
        const pipeline_id = $("[name='pipeline_id']").val();
        const user_id = $("[name='user_id']").val();
        const nome = $("[name='nome']").val();
        const ativo_receptivo = $("[name='ativo_receptivo']").val();
        const canal = $("[name='canal']").val();
        const tipo_atendimento_id = $("[name='tipo_atendimento_id']").val();
        const motivo_perda_id = $("[name='motivo_perda_id']").val();
        const produto_id = $("[name='produto_id']").val();
        const c_produtos = $("[name='c_produtos']").is(":checked");
        const queryString = `?data_inicio=${data_inicio}&data_fim=${data_fim}&status=${status}&pipeline_id=${pipeline_id}&nome=${nome}&user_id=${user_id}&ativo_receptivo=${ativo_receptivo}&canal=${canal}&tipo_atendimento_id=${tipo_atendimento_id}&motivo_perda_id=${motivo_perda_id}&produto_id=${produto_id}&c_produtos=${c_produtos}`;
        const url = siteUrl(
            `/painel/fluxo-oportunidades/lista/exportar/${queryString}`
        );
        $("#linkExportReport").attr("href", url);
    });

    $(".js-form-filtro-tarefas").on("change", function () {
        const dono = $("[name='dono']").val();
        const situacao = $("[name='situacao']").val();
        const status = $("[name='status']").val();
        const pipeline_id = $("[name='pipeline_id']").val();
        const periodo_data_inicial = $("[name='periodo_data_inicial']").val();
        const periodo_data_final = $("[name='periodo_data_final']").val();
        const periodo = $("[name='periodo']").val();
        const queryString = `?dono=${dono}&situacao=${situacao}&status=${status}&pipeline_id=${pipeline_id}&periodo_data_final=${periodo_data_final}&periodo_data_inicial=${periodo_data_inicial}&periodo=${periodo}`;
        const url = siteUrl(
            `/painel/oportunidades-tarefas-exportar/${queryString}`
        );
        $("#linkExportReport").attr("href", url);
    });

    $(".js-form-filtro-pedidos").on("change", function () {
        const cliente = $("[name='cliente']").val();
        const id = $("[name='id']").val();
        const status_id = $("[name='status_id']").val();
        const isCupom = $("[name='isCupom']").is(":checked");
        const isCurenovacao_automaticapom = $(
            "[name='isCurenovacao_automaticapom']"
        ).val();
        const periodo_ordem = $("[name='periodo_ordem']").val();
        const periodo_data_inicial = $("[name='periodo_data_inicial']").val();
        const periodo_data_final = $("[name='periodo_data_final']").val();
        const tipo_pagamento_id = $("[name='tipo_pagamento_id']").val();
        const tipo_entrega_id = $("[name='tipo_entrega_id']").val();
        const queryString = `?cliente=${cliente}&id=${id}&status_id=${status_id}&isCupom=${isCupom}&periodo_ordem=${periodo_ordem}&isCurenovacao_automaticapom=${isCurenovacao_automaticapom}&periodo_data_inicial=${periodo_data_inicial}&periodo_data_final=${periodo_data_final}&tipo_pagamento_id=${tipo_pagamento_id}&tipo_entrega_id=${tipo_entrega_id}`;
        const url = siteUrl(
            `/painel/pedidos/exportar-relatorio/${queryString}`
        );
        $("#linkExportReport").attr("href", url);
    });

    $(".js-form-filtro-produtos").on("change", function () {
        const nome = $("[name='nome']").val();
        const status_produto = $("[name='status_produto']").val();
        const status_estoque = $("[name='status_estoque']").val();
        const sku = $("[name='sku']").val();
        const ref_fornecedor = $("[name='ref_fornecedor']").val();
        const fora_de_portfolio = $("[name='fora_de_portfolio']").val();
        const order_field = $("[name='order_field']").val();
        const locais_retirada_id = $("[name='locais_retirada_id']").val();
        const display = $("[name='display']").val();
        const queryString = `?nome=${nome}&status_produto=${status_produto}&status_estoque=${status_estoque}&sku=${sku}&order_field=${order_field}&fora_de_portfolio=${fora_de_portfolio}&locais_retirada_id=${locais_retirada_id}&display=${display}&ref_fornecedor=${ref_fornecedor}`;
        const url = siteUrl(
            `/painel/pedidos/exportar-relatorio/${queryString}`
        );
        $("#linkExportReport").attr("href", url);
    });

    $(".js-busca-form-filtros-clientes").on("change", function () {
        const nome = $("[name='nome']").val();
        const email = $("[name='email']").val();
        const telefone1 = $("[name='telefone1']").val();
        const tag_id = $("[name='tag_id']").val();
        let data_inicio = $("[name='data_inicio']").val();
        data_inicio = data_inicio.replace("/", "%2F").replace("/", "%2F");
        let data_fim = $("[name='data_fim']").val();
        data_fim = data_fim.replace("/", "%2F").replace("/", "%2F");
        const carrinho = $("[name='carrinho']")[0].checked ? 1 : 0;
        const queryString = `?nome=${nome}&telefone1=${telefone1}&email=${email}&carrinho=${carrinho}&tag_id=${tag_id}&data_inicio=${data_inicio}&data_fim=${data_fim}`;
        const url = siteUrl(`/painel/clientes/exportar${queryString}`);
        $(".linkExportCliente").attr("href", url);
        console.log(url);
    });

    $(".js-busca-form-filtros-relatorio-assinatura").on("change", function () {
        const cliente = $("[name='cliente']").val();
        const id = $("[name='id']").val();
        const status_id = $("[name='status_id']").val();
        const status_entrega_id = $("[name='status_entrega_id']").val();
        const isCupom = $("[name='isCupom']")[0].checked ? 1 : 0;
        const produtos = $("[name='produtos']")[0].checked ? 1 : 0;
        const periodo_assinatura = $("[name='periodo_assinatura']").val();
        const periodo_data_inicial = $("[name='periodo_data_inicial']").val();
        const periodo_data_final = $("[name='periodo_data_final']").val();
        const plano = $("[name='plano']").val();
        const produto_id = $("[name='produto_id']").val();
        const queryString = `?cliente=${cliente}&id=${id}&status_id=${status_id}&status_entrega_id=${status_entrega_id}&isCupom=${isCupom}&produtos=${produtos}&periodo_assinatura=${periodo_assinatura}}&periodo_data_inicial=${periodo_data_inicial}&periodo_data_final=${periodo_data_final}&plano=${plano}&produto_id=${produto_id}`;
        const url = siteUrl(
            `/painel/relatorio-assinaturas/download-excel/${queryString}`
        );
        $("#assinaturas-exporta").attr("href", url);
    });

    $(".js-relatorio-assinatura-com-produto").on("change", function () {
        if (
            $(this).is(":checked") &&
            $(".js-buscar-produto-assinatura").hasClass("d-none")
        ) {
            $(".js-buscar-produto-assinatura").removeClass("d-none");
        }
        if (
            !$(this).is(":checked") &&
            !$(".js-buscar-produto-assinatura").hasClass("d-none")
        ) {
            $("[name='produto_busca']").val("");
            $("[name='produto_id']").val("");
            $(".js-buscar-produto-assinatura").addClass("d-none");
        }
    });

function aplicarMascaraTelefone(numero) {
    numero = numero.replace(/\D/g, "");

    if (numero.startsWith("0800")) {
        return numero.replace(/(\d{4})(\d{3})(\d{4})/, "$1 $2 $3");
    }

    if (numero.length === 11) {
        return numero.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    }

    if (numero.length === 10) {
        return numero.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }

    return numero.replace(/(\d{2})(\d{4,5})?(\d{0,4})?/, function(_, ddd, parte1, parte2) {
        let resultado = `(${ddd}`;
        if (parte1) resultado += `) ${parte1}`;
        if (parte2) resultado += `-${parte2}`;
        return resultado;
    });
}


    function removerAcentos(texto) {
        return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    $(context).on("click", ".js-preencher-cadastro-cliente-ccl", function () {
        let data = window.dadosCadastroClienteCCL.data;
        let municipio = null;
        let uf = null;
        if (window.dadosCadastroClienteCCL.tipoPessoa == "pf") {
            $("input[name='rg']").val(data.RG);
            $("input[name='data_nascimento']").val(data.dataDeNascimento);

            $("input[name='email']").val(data.email);
            $("input[name='bairro']").val(data.endereco.bairro);
            $("input[name='cep']").val(functions.preparaCepCCL(data.endereco.cep));
            municipio = data.endereco.municipio;
            uf = data.endereco.uf;
            //$("select[name='cidade_id']").val(0);
            //$("select[name='estado_id']").val(0);
            $("input[name='complemento']").val(data.endereco.complemento);
            $("input[name='numero']").val(data.endereco.numero);
            $("input[name='rua']").val(data.endereco.logradouro);
            if (!$("input[name='telefone1']").prop("readonly")) {
                $("input[name='telefone1']").val(aplicarMascaraTelefone(data.telefone));
            }

            $("input[name='nome']").val(data.nome);

            $(".js-cliente-pj").hide();
            $(".js-cliente-pf").show();
        } else if (window.dadosCadastroClienteCCL.tipoPessoa == "pj") {
            let dataAbertura = data.dataDeAbertura;
            $("input[name='data_cadastro']").val(dataAbertura);
            $("input[name='email']").val(data.email);
            $("input[name='bairro']").val(data.bairroEmpresa);
            $("input[name='cep']").val(functions.preparaCepCCL(data.cepEmpresa));
            municipio = data.cidadeEmpresa;
            uf = data.estadoEmpresa;
            //$("select[name='cidade_id']").val(0);
            //$("select[name='estado_id']").val(0);
            $("input[name='complemento']").val(data.complementoEmpresa);
            $("input[name='numero']").val(data.numeroEmpresa);
            $("input[name='rua']").val(data.ruaEmpresa);
            if (!$("input[name='telefone1']").prop("readonly")) {
                $("input[name='telefone1']").val(aplicarMascaraTelefone(data.telefone));
            }

            $("input[name='nome_fantasia']").val(data.nomeFantasia);
            $("input[name='razao_social']").val(data.razaoSocial);

            $(".js-cliente-pf").hide();
            $(".js-cliente-pj").show();
        }

        if (!isEmpty(uf)) {
            $("select[name='estado_id'] option").filter(function() {
                return $(this)
                    .text()
                    .toLowerCase()
                    .includes(uf.toLowerCase() + ' -');
            }).prop('selected', true);

            $("select[name='estado_id']").trigger('change');

            if (!isEmpty(municipio)) {
                setTimeout(() => {
                    $("select[name='cidade_id'] option").filter(function() {
                        return removerAcentos($(this).text()
                            .toLowerCase())
                            .includes(removerAcentos(municipio.toLowerCase()));
                    }).prop('selected', true);
                }, 2000);
            }
        } else {
            functions.buscaCEP($("input[name='cep']").val(), context);
        }

        $("#modalAlertPainel").modal("toggle");
    });

    $(".js-tipo-config-template", context).on("change", function () {
        const dataId = $(this).find(":selected").data("id");
        $(".js-campo-exc-gupshup").each(function () {
            if (dataId == 1) {
                if ($(this).hasClass("d-none")) {
                    $(this).removeClass("d-none");
                }
            } else if (dataId == 2) {
                if (!$(this).hasClass("d-none")) {
                    $(this).addClass("d-none");
                }
            }
        });
    });
}
