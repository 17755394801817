export function checkTrackingBlock() {
    if (!checkTrackingBlock.promise) {
        checkTrackingBlock.promise = new Promise(function (resolve, reject) {
            var time = Date.now();
            var img = new Image();
            img.onload = resolve;
            img.onerror = function () {
                if (Date.now() - time < 50) {
                    reject(new Error("Rejected."));
                } else {
                    reject(new Error("Takes too long."));
                }
            };
            img.src = "//www.facebook.com/tr/";
        })
            .then((result) => {
                return false;
            })
            .catch((e) => {
                $("#alertAdblock").removeClass("d-none");
            });
    }
}

export function gerarIDUnico() {
    return Math.floor(Math.random() * 1000000).toString();
}

export function idUnico() {
    return (
        Date.now().toString() +
        Math.floor(Math.random() * 1000000)
            .toString()
            .padStart(6, "0")
    );
}

export function numeroExisteNaString(
    numero,
    stringDeNumeros,
    $separador = ","
) {
    if (stringDeNumeros.toString().indexOf(",") < 0) {
        if (numero == stringDeNumeros) {
            return true;
        }
    } else {
        const numeros = stringDeNumeros.split($separador).map(Number);
        return numeros.includes(numero);
    }
    return false;
}

export function desenhaQrCode(outputElem, qrCode) {
    while (outputElem.firstChild !== null)
        outputElem.removeChild(outputElem.firstChild);

    const errCorLvl = window.qrcodegen.QrCode.Ecc.LOW;
    const qr = window.qrcodegen.QrCode.encodeText(qrCode, errCorLvl);
    drawCanvas(qr, 7, 2, "#FFFFFF", "#000000", outputElem);
}

export function drawCanvas(
    qr,
    scale,
    border,
    lightColor,
    darkColor,
    outputElem
) {
    if (scale <= 0 || border < 0) throw new RangeError("Valor fora do range");
    const width = (qr.size + border * 2) * scale;
    let canvas = document.createElement("canvas");
    outputElem.appendChild(canvas);
    canvas.width = width;
    canvas.height = width;
    let ctx = canvas.getContext("2d");
    for (let y = -border; y < qr.size + border; y++) {
        for (let x = -border; x < qr.size + border; x++) {
            ctx.fillStyle = qr.getModule(x, y) ? darkColor : lightColor;
            ctx.fillRect(
                (x + border) * scale,
                (y + border) * scale,
                scale,
                scale
            );
        }
    }
}

export function resizeIframe(iframe) {
    var iframe = document.getElementById(iframe);

    iframe.onload = function () {
        var heightpx = iframe.contentWindow.document.body.scrollHeight + "px";
        iframe.style.height = heightpx;
    };
}

export function dateToBrDate(data) {
    if (data == "" || data == null || data == "undefined" || data == "NULL") {
        return "--/--/---- - --:--";
    } else {
        var dataAtual = new Date(data);

        var dia = ("0" + dataAtual.getDate()).slice(-2);
        var mes = ("0" + (dataAtual.getMonth() + 1)).slice(-2);
        var ano = dataAtual.getFullYear();

        var hora = ("0" + dataAtual.getHours()).slice(-2);
        var minutos = ("0" + dataAtual.getMinutes()).slice(-2);

        return dia + "/" + mes + "/" + ano + " " + hora + ":" + minutos;
    }
}

export function delay(callback, ms) {
    var tempo = 0;
    return function () {
        var context = this,
            args = arguments;
        clearTimeout(tempo);
        tempo = setTimeout(function () {
            callback.apply(context, args);
        }, ms || 0);
    };
}

export function generateRandomToken(length) {
    const array = new Uint32Array(length);
    window.crypto.getRandomValues(array);
    return Array.from(array, (dec) => dec.toString(36))
        .join("")
        .substring(0, length);
}

export function setalturaTextArea(input) {
    input.attr("style", `height: auto`);
    let altura = input.prop("scrollHeight");
    altura = Math.max(38, Math.min(altura, 132));
    input.attr("style", `height: ${altura}px`);
}

export function limpaMoeda(valor) {
    if (valor == "" || valor == "undefined" || valor == null) {
        return 0;
    }

    if (typeof valor === "string") {
        valor = valor.replace(/\./g, "");
        valor = valor.replace(/,/g, ".");
        valor = parseFloat(valor);
    }

    return valor;
}

export function isEmpty(stringVal) {
    if (
        stringVal == "" ||
        stringVal == null ||
        stringVal == "undefined" ||
        stringVal == "NULL" ||
        stringVal == "0" ||
        stringVal == undefined
    ) {
        return true;
    } else {
        return false;
    }
}

export function carregarTabelaChat(targetTabela, id) {
    $(targetTabela).html(`
            <div class="mt-5 mb-4 pb-1" style="position: relative;">
                <div class="loading-circle"><div></div><div></div><div></div><div></div></div>
            </div>
        `);
    $.ajax({
        url: siteUrl(`/painel/oportunidade-tabela-produto-chat/${id}`),
        type: "get",
        success: function (res) {
            $(targetTabela).html(res);
        },
    });
}
